import CalendarSVG from "../_images/icons/calendar";
import ClockSVG from "../_images/icons/clock";
import MaskSVG from "../_images/icons/mask";
import ReservedSVG from "../_images/icons/Reserved";

const MidSec = () => {
  return (
    <section className="py-0 py-xl-5">
      <div className="container">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-3">
            <div className="d-flex justify-content-center align-items-center p-4 bg-warning bg-opacity-15 rounded-3 shadowing">
              <ClockSVG />

              <div className="ms-4 h6 fw-normal adjust-marg-clock ">
                <div className="d-flex ">
                  <h5 className="purecounter mb-0 fw-bold ">Radno vrijeme</h5>
                </div>
                <p className="mb-0">08-16h</p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="d-flex justify-content-center align-items-center p-4 bg-blue bg-opacity-10 rounded-3 shadowing">
              <CalendarSVG />

              <div className="ms-4 h6 fw-normal">
                <div className="d-flex">
                  <h5 className="purecounter mb-0 fw-bold">Radni dani</h5>
                </div>
                <p className="mb-0">Pon-Pet</p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="d-flex justify-content-center align-items-center p-4 bg-purple bg-opacity-10 rounded-3 shadowing">
              <ReservedSVG />

              <div className="ms-4 h6 fw-normal">
                <div className="d-flex">
                  <h5 className="purecounter mb-0 fw-bold">Rezervacije</h5>
                </div>
                <h5 className="purecounter mb-0 fw-bold">obavezne</h5>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="d-flex justify-content-center align-items-center p-4 bg-info bg-opacity-10 rounded-3 shadowing">
              <MaskSVG />
              <div className="ms-4 h6 fw-normal">
                <div className="d-flex">
                  <h5 className="purecounter mb-0 fw-bold">Maske</h5>
                </div>
                <h5 className="purecounter mb-0 fw-bold">obavezne</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MidSec;

import React, { Component } from "react";
import { Link } from "react-router-dom";

class Contact extends Component {
  render() {
    return (
      <div>
        <section class="container pt-5 pb-lg-2 pb-xl-4 py-xxl-5 my-5 kontakt">
          <h1 class="display-2 pb-2 mb-sm-4 mb-lg-5">Kontakt</h1>

          <div class="border-top pb-sm-3 pt-4">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pt-2">
              <div class="col pb-2">
                <h4 class="mb-lg-4">Adresa</h4>
                <p class="fs-lg fw-medium pb-3 mb-3">
                  69, Jevrejska, Banja Luka 78000
                </p>
                <Link to='/kontakt'>
                <span class="btn dugmence shadowing">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28"
                    width="28"
                    viewBox="10 -4 35 60"
                  >
                    <path d="M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5Zm0 16.55q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z" />
                  </svg>
                  Dobiti putanju
                </span>
                </Link>
              </div>

              <div class="col pb-2">
                <h4 class="mb-lg-4">Telefon</h4>
                <ul class="list-unstyled mb-0">
                  <li class="pb-1 mb-2">
                    <span class="d-block fs-sm text-muted mb-1">Fiksni</span>
                    <a class="nav-link fs-lg p-0" href="tel:+178632256033">
                      +1 (786) 322 560 33
                    </a>
                  </li>
                  <li class="pb-1 mb-2">
                    <span class="d-block fs-sm text-muted mb-1">Mobilni</span>
                    <a class="nav-link fs-lg p-0" href="tel:+178630056044">
                      +1 (786) 300 560 44
                    </a>
                  </li>
                  <li>
                    <span class="d-block fs-sm text-muted mb-1">
                      Glavna kancelarija
                    </span>
                    <a class="nav-link fs-lg p-0" href="tel:+178630050055">
                      +1 (786) 300 500 55
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col pb-2">
                <h4 class="mb-lg-4">Termini</h4>
                <ul class="list-unstyled mb-0">
                  <li class="pb-1 mb-2">
                    <span class="d-block fs-sm text-muted mb-1">Pon - Uto</span>
                    <div class="d-flex align-items-center">
                      <span class="text-nav fs-lg fw-medium">08:00</span>
                      <span
                        class="border-top mx-4 spanLine"
                        // style="width: 36px; height: 1px;"
                      ></span>
                      <span class="text-nav fs-lg fw-medium">16:00</span>
                    </div>
                  </li>
                  <li class="pb-1 mb-2">
                    <span class="d-block fs-sm text-muted mb-1">Sri - Čet</span>
                    <div class="d-flex align-items-center">
                      <span class="text-nav fs-lg fw-medium">10:00</span>
                      <span
                        class="border-top mx-4 spanLine"
                        // style="width: 36px; height: 1px;"
                      ></span>
                      <span class="text-nav fs-lg fw-medium">18:00</span>
                    </div>
                  </li>
                  <li>
                    <span class="d-block fs-sm text-muted mb-1">Pet</span>
                    <div class="d-flex align-items-center">
                      <span class="text-nav fs-lg fw-medium">10:00</span>
                      <span
                        class="border-top mx-4 spanLine"
                        // style="width: 36px; height: 1px;"
                      ></span>
                      <span class="text-nav fs-lg fw-medium">16:00</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="col pb-2">
                <h4 class="mb-lg-4">Email</h4>
                <a class="nav-link fs-lg p-0" href="mailto:email@eprimjer.com">
                xquisite@saloon.com
                </a>
              </div>
            </div>
          </div>
        </section>
        <div class="mapouter">
          <div class="gmap_canvas map">
            <iframe
              title="Xquisite"
              class="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=69, Jevrejska, Banja Luka 78000&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

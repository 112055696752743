const MaskSVG = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
   height='50px'
   width='50px'
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    
    >
      <path
        fill="#C7E9F4"
        d="M323.649,32.273l-11.685,11.686l-8.034-8.034c-8.427-8.427-22.088-8.427-30.515,0L40.92,268.42
	c-8.427,8.427-8.427,22.088,0,30.515l8.034,8.034l-16.681,16.679c-43.031,43.031-43.031,113.047,0,156.078s113.047,43.031,156.078,0
	l16.679-16.679l8.034,8.034c8.427,8.427,22.088,8.427,30.515,0l232.495-232.495c8.427-8.427,8.427-22.088,0-30.515l-8.034-8.034
	l11.685-11.685c43.031-43.031,43.031-113.047,0-156.078C436.696-10.757,366.68-10.757,323.649,32.273z M449.212,157.836
	l-11.686,11.685l-21.636-21.636c-8.427-8.427-22.088-8.427-30.515,0L152.881,380.379c-8.427,8.427-8.427,22.088,0,30.515
	l21.636,21.636l-16.679,16.679c-26.205,26.205-68.843,26.205-95.048,0s-26.205-68.843,0-95.048l16.679-16.681l21.636,21.636
	c8.427,8.427,22.088,8.427,30.515,0l232.495-232.491c8.427-8.427,8.427-22.088,0-30.515l-21.636-21.636l11.685-11.686
	c26.205-26.205,68.843-26.205,95.047,0C475.417,88.994,475.417,131.631,449.212,157.836z"
      />
      <g>
        <path
          fill="#80D0E1"
          d="M431.147,163.142l-263.01,263.01l-82.289-82.289l263.01-263.01L431.147,163.142z"
        />
        <path
          fill="#80D0E1"
          d="M323.649,32.273l-11.685,11.686l-8.034-8.034c-8.427-8.427-22.088-8.427-30.515,0L154.67,154.67
		l90.701,90.701l118.745-118.745c8.427-8.427,8.427-22.088,0-30.515L342.48,74.475l11.685-11.686
		c26.205-26.205,68.843-26.205,95.047,0c26.205,26.205,26.205,68.842,0,95.048l-11.686,11.685l-21.636-21.636
		c-8.427-8.427-22.088-8.427-30.515,0L266.63,266.631l90.701,90.701l118.745-118.745c8.427-8.427,8.427-22.088,0-30.515
		l-8.034-8.034l11.685-11.685c43.031-43.031,43.031-113.047,0-156.078C436.696-10.757,366.68-10.757,323.649,32.273z"
        />
      </g>
      <rect
        x="228.245"
        y="130.822"
        transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 685.0403 94.2649)"
        fill="#51B3DA"
        width="189.505"
        height="116.373"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default MaskSVG;

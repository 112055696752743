import React from "react";
import "./_styles/style.css";
import { Routes, Route } from "react-router";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Contact from "./components/Contact/Contact";
import Footer from './components/Footer/footer'

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/galerija" exact element={<Gallery />} />
        <Route path="/kontakt" exact element={<Contact />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;

const CalendarSVG = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 0 512 512"
      
    >
      <g>
        <g>
          <path
            fill="#D8DCE1"
            d="M0,134v328c0,22.055,17.945,40,40,40h432c22.055,0,40-17.945,40-40V134H0z"
          />
        </g>
        <g>
          <path
            fill="#FF4F19"
            d="M472,22H40C17.945,22,0,39.945,0,62v72h512V62C512,39.945,494.054,22,472,22z M64,102
                c-13.255,0-24-10.745-24-24s10.745-24,24-24s24,10.745,24,24S77.255,102,64,102z M448,102c-13.255,0-24-10.745-24-24
                s10.745-24,24-24s24,10.745,24,24S461.255,102,448,102z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M256,206c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S273.648,206,256,206z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M176,206c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S193.648,206,176,206z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M96,206c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S113.648,206,96,206z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M336,206c-17.648,0-32,14.352-32,32s14.352,32,32,32c17.648,0,32-14.352,32-32
                    S353.648,206,336,206z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M416,270c17.648,0,32-14.352,32-32s-14.352-32-32-32c-17.648,0-32,14.352-32,32
                    S398.351,270,416,270z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M256,286c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S273.648,286,256,286z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M176,286c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S193.648,286,176,286z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M96,286c-17.648,0-32,14.352-32,32s14.352,32,32,32s32-14.352,32-32S113.648,286,96,286z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M336,286c-17.648,0-32,14.352-32,32s14.352,32,32,32c17.648,0,32-14.352,32-32
                    S353.648,286,336,286z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M416,286c-17.648,0-32,14.352-32,32s14.352,32,32,32c17.648,0,32-14.352,32-32
                    S433.648,286,416,286z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M256,366c-17.648,0-32,14.352-32,32c0,17.648,14.352,32,32,32s32-14.352,32-32
                    C288,380.351,273.648,366,256,366z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M176,366c-17.648,0-32,14.352-32,32c0,17.648,14.352,32,32,32s32-14.352,32-32
                    C208,380.351,193.648,366,176,366z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M96,366c-17.648,0-32,14.352-32,32c0,17.648,14.352,32,32,32s32-14.352,32-32
                    C128,380.351,113.648,366,96,366z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M336,366c-17.648,0-32,14.352-32,32c0,17.648,14.352,32,32,32c17.648,0,32-14.352,32-32
                    C368,380.351,353.648,366,336,366z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M416,366c-17.648,0-32,14.352-32,32c0,17.648,14.352,32,32,32c17.648,0,32-14.352,32-32
                    C448,380.351,433.648,366,416,366z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#5C546A"
              d="M64,90c-6.625,0-12-5.371-12-12V22c0-6.629,5.375-12,12-12s12,5.371,12,12v56
                    C76,84.629,70.625,90,64,90z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#5C546A"
              d="M448,90c-6.625,0-12-5.371-12-12V22c0-6.629,5.375-12,12-12s12,5.371,12,12v56
                    C460,84.629,454.625,90,448,90z"
            />
          </g>
        </g>
        <g>
          <circle fill="#FFD200" cx="96" cy="238" r="32" />
        </g>
        <g>
          <circle fill="#FF9600" cx="256" cy="398" r="32" />
        </g>
        <g>
          <circle fill="#FF4F19" cx="336" cy="318" r="32" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default CalendarSVG;

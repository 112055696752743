const ClockSVG = () => {
    return (

    <svg version="1.1" id="Layer_1" x="0px" y="0px" height='50px' width="50px"
         viewBox="0 0 502 502">
    <g>
        <g>
            <path fill='#3CB7C6' d="M251,10C117.899,10,10,117.899,10,251s107.899,241,241,241s241-107.899,241-241S384.101,10,251,10z
                 M251,432.934C150.52,432.934,69.066,351.48,69.066,251S150.52,69.066,251,69.066S432.934,150.52,432.934,251
                S351.48,432.934,251,432.934z"/>
            <path d="M251,502c-67.045,0-130.076-26.108-177.483-73.516C26.108,381.076,0,318.044,0,251S26.108,120.924,73.517,73.516
                C120.924,26.108,183.955,0,251,0s130.076,26.108,177.483,73.516C475.892,120.924,502,183.956,502,251
                s-26.108,130.076-73.517,177.484C381.076,475.892,318.045,502,251,502z M251,20C123.626,20,20,123.626,20,251s103.626,231,231,231
                s231-103.626,231-231S378.374,20,251,20z M251,442.935c-105.833,0-191.935-86.102-191.935-191.935S145.167,59.065,251,59.065
                S442.935,145.167,442.935,251S356.833,442.935,251,442.935z M251,79.065c-94.805,0-171.935,77.129-171.935,171.935
                S156.195,422.935,251,422.935S422.935,345.805,422.935,251S345.805,79.065,251,79.065z"/>
        </g>
        <g>
            <circle fill='#FFFFFF' cx="251" cy="251" r="181.934"/>
            <path d="M251,442.935c-105.833,0-191.935-86.102-191.935-191.935S145.167,59.065,251,59.065S442.935,145.167,442.935,251
                S356.833,442.935,251,442.935z M251,79.065c-94.805,0-171.935,77.129-171.935,171.935S156.195,422.935,251,422.935
                S422.935,345.805,422.935,251S345.805,79.065,251,79.065z"/>
        </g>
        <g>
            <path d="M180.272,163.642c-3.092,0-6.142-1.429-8.098-4.123l-17.823-24.531c-3.246-4.468-2.256-10.722,2.212-13.968
                c4.467-3.245,10.723-2.256,13.968,2.212l17.823,24.531c3.246,4.468,2.256,10.722-2.212,13.968
                C184.368,163.021,182.311,163.642,180.272,163.642z"/>
        </g>
        <g>
            <path d="M136.55,223.815c-1.024,0-2.065-0.159-3.092-0.492l-28.838-9.37c-5.253-1.707-8.127-7.349-6.421-12.601
                c1.706-5.253,7.351-8.127,12.601-6.42l28.838,9.37c5.253,1.707,8.127,7.349,6.421,12.601
                C144.686,221.128,140.765,223.815,136.55,223.815z"/>
        </g>
        <g>
            <path d="M107.708,307.561c-4.216,0-8.136-2.687-9.509-6.913c-1.706-5.252,1.168-10.894,6.421-12.601l28.838-9.37
                c5.255-1.708,10.894,1.168,12.601,6.42c1.706,5.252-1.168,10.894-6.421,12.601l-28.838,9.37
                C109.773,307.402,108.731,307.561,107.708,307.561z"/>
        </g>
        <g>
            <path d="M162.434,382.891c-2.039,0-4.095-0.621-5.87-1.91c-4.468-3.247-5.458-9.5-2.212-13.968l17.823-24.531
                c3.246-4.468,9.499-5.458,13.968-2.212c4.468,3.247,5.458,9.5,2.212,13.968l-17.823,24.531
                C168.575,381.461,165.525,382.891,162.434,382.891z"/>
        </g>
        <g>
            <path d="M251,411.665c-5.522,0-10-4.477-10-10v-30.322c0-5.523,4.478-10,10-10s10,4.477,10,10v30.322
                C261,407.188,256.522,411.665,251,411.665z"/>
        </g>
        <g>
            <path d="M339.566,382.891c-3.092,0-6.142-1.429-8.098-4.123l-17.823-24.531c-3.246-4.468-2.256-10.722,2.212-13.968
                c4.467-3.245,10.723-2.256,13.968,2.212l17.823,24.531c3.246,4.468,2.256,10.722-2.212,13.968
                C343.662,382.27,341.604,382.891,339.566,382.891z"/>
        </g>
        <g>
            <path d="M394.292,307.561c-1.024,0-2.065-0.159-3.092-0.492l-28.838-9.37c-5.253-1.707-8.127-7.349-6.421-12.601
                c1.707-5.252,7.351-8.127,12.601-6.42l28.838,9.37c5.253,1.707,8.127,7.349,6.421,12.601
                C402.428,304.874,398.507,307.561,394.292,307.561z"/>
        </g>
        <g>
            <path d="M365.45,223.815c-4.216,0-8.136-2.687-9.509-6.913c-1.706-5.252,1.168-10.894,6.421-12.601l28.838-9.37
                c5.253-1.707,10.895,1.168,12.601,6.42s-1.168,10.894-6.421,12.601l-28.838,9.37C367.516,223.656,366.474,223.815,365.45,223.815z
                "/>
        </g>
        <g>
            <path d="M321.728,163.642c-2.038,0-4.095-0.621-5.87-1.91c-4.468-3.247-5.458-9.5-2.212-13.968l17.823-24.531
                c3.247-4.469,9.501-5.457,13.968-2.212c4.468,3.247,5.458,9.5,2.212,13.968l-17.823,24.531
                C327.869,162.212,324.819,163.642,321.728,163.642z"/>
        </g>
        <path d="M323.999,227.281c-1.706-5.252-7.347-8.128-12.601-6.42L261,237.236V100.335c0-5.523-4.478-10-10-10s-10,4.477-10,10V251
            c0,6.678,6.727,11.563,13.09,9.511l63.488-20.628C322.831,238.175,325.705,232.534,323.999,227.281
            C322.293,222.029,325.705,232.534,323.999,227.281z"/>
        <g>
            <path d="M309.753,57.638c-0.918,0-1.85-0.127-2.775-0.394c-18.107-5.222-36.94-7.869-55.978-7.869c-5.522,0-10-4.477-10-10
                s4.478-10,10-10c20.912,0,41.61,2.911,61.519,8.652c5.307,1.53,8.368,7.073,6.838,12.379
                C318.093,54.788,314.095,57.638,309.753,57.638z"/>
        </g>
        <g>
            <path d="M50.938,330.176c-4.145,0-8.02-2.598-9.45-6.735C33.45,300.194,29.375,275.821,29.375,251
                c0-51.012,17.755-100.812,49.994-140.227c31.789-38.864,76.182-66.026,125-76.482c5.405-1.154,10.716,2.284,11.873,7.684
                c1.156,5.4-2.283,10.716-7.684,11.873C116.321,73.604,49.375,156.519,49.375,251c0,22.592,3.706,44.766,11.015,65.905
                c1.805,5.22-0.964,10.914-6.184,12.719C53.124,329.998,52.021,330.176,50.938,330.176z"/>
        </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
     );
}
 
export default ClockSVG;
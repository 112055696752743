import Pagination from "../../../common/pagination";
import { paginate } from "../../../utils/paginate";
import CardData from "../../../common/card/data";
import Card from "../../../common/card/card";

import { getAlbum } from "../../../db/GalleryDB";

import React, { Component } from "react";

class Album extends Component {
  state = {
    cards: [],
    pageSize: 9,
    currentPage: 1,
    currentIndex: "",
    currentImage: "",
    active: "",
  };

  componentDidMount() {
    //number of cards
    this.setState({ cards: getAlbum() });
  }

  getLength = () => {
    const { pageSize, currentPage, cards } = this.state;
    const cardsGet = [...cards];
    const allCards = paginate(cardsGet, currentPage, pageSize);

    const allCardsLength = cardsGet.length;

    return { allCards, allCardsLength };
  };

  render() {
    const topPage = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    };

    const { pageSize, currentPage, cards, currentIndex } = this.state;
    const { allCards, allCardsLength } = this.getLength();

    const handlePageChange = (page) => {
      this.setState({ currentPage: page });
      topPage();
    };

    const handleNextPage = () => {
      if (currentPage < allCardsLength / pageSize) {
        this.setState({ currentPage: currentPage + 1 });
        topPage();
      }
    };

    const handlePrevPage = () => {
      if (currentPage !== 1) {
        this.setState({ currentPage: currentPage - 1 });
        topPage();
      }
    };
    ///////////////
    const handleImage = (index) => {
      this.setState({ currentIndex: index - 1 }, function () {
        this.setState({ currentImage: cards[this.state.currentIndex].img });
        this.setState({ active: "-active" });
      });
    };

    const handleImageNext = () => {
      if (currentIndex < cards.length - 1) {
        this.setState({ currentIndex: currentIndex + 1 }, function () {
          this.setState({ currentImage: cards[this.state.currentIndex].img });
        });
      } else {
        this.setState({ currentIndex: 0 }, function () {
          this.setState({ currentImage: cards[this.state.currentIndex].img });
        });
      }
    };

    const handleImagePrev = () => {
      if (currentIndex > 0) {
        this.setState({ currentIndex: currentIndex - 1 }, function () {
          this.setState({ currentImage: cards[this.state.currentIndex].img });
        });
      } else {
        this.setState({ currentIndex: cards.length - 1 }, function () {
          this.setState({ currentImage: cards[this.state.currentIndex].img });
        });
      }
    };

    const stop = () => {
      this.setState({ active: "" });
      this.setState({ currentImage: "" });
      this.setState({ currentID: 0 });
    };
    ///////////////////
    return (
      <React.Fragment>
        {
          <div className={`currentImage${this.state.active}`}>
            <button id="stop" className="buttonClose" onClick={stop}>
              <svg xmlns="http://www.w3.org/2000/svg" height="35" width="35" viewBox="0 30 50 10">
                <path d="m12.45 38.85-3.3-3.3L20.7 24 9.15 12.45l3.3-3.3L24 20.7 35.55 9.15l3.3 3.3L27.3 24l11.55 11.55-3.3 3.3L24 27.3Z" />
              </svg>
            </button>
            <button
              className="buttonPrev"
              onClick={() => {
                handleImagePrev();
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path d="M21.2 45.2 0 24 21.2 2.8l4 4.05L8.05 24 25.2 41.15Z" />
              </svg>
            </button>
            <button
              className="buttonNext"
              onClick={() => {
                handleImageNext();
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path d="m15.2 45.1-4-4.05L28.35 23.9 11.2 6.75l4-4.05 21.2 21.2Z" />
              </svg>
            </button>
            <div className="fullScreenImage">
              <img
                src={this.state.currentImage}
                alt={"Image " + currentIndex}
              />
            </div>
            <div className="counter-box">
              <span className="counter">{this.state.currentIndex + 1}</span>
              <span className="counter">/</span>
              <span className="counter">{cards.length}</span>
            </div>
          </div>
        }

        <div className="album py-5 bg-light" id="cardContent">
          <div className="container">
            <div
              id="cardlist"
              className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3"
            >
              {allCards.map((card) => (
                <li key={card._id} onClick={() => handleImage(card._id)}>
                  <Card
                    //data={card.content}
                    data={<CardData id={card._id} img={card.img} />}
                  />
                </li>
              ))}
            </div>

            <Pagination
              itemsCount={allCardsLength}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              nextPage={handleNextPage}
              prevPage={handlePrevPage}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Album;

import slide1 from '../components/Home/_images/1.jpg'
import slide2 from '../components/Home/_images/2.jpg'
import slide3 from '../components/Home/_images/3.jpg'

//friz

import friz1 from '../components/Gallery/_images/frizure/1.jpg'
import friz2 from '../components/Gallery/_images/frizure/2.jpg'
import friz3 from '../components/Gallery/_images/frizure/3.jpg'
import friz4 from '../components/Gallery/_images/frizure/4.jpg'
import friz5 from '../components/Gallery/_images/frizure/5.jpg'
import friz6 from '../components/Gallery/_images/frizure/6.jpg'
import friz7 from '../components/Gallery/_images/frizure/7.jpg'
import friz8 from '../components/Gallery/_images/frizure/8.jpg'
import friz9 from '../components/Gallery/_images/frizure/9.jpg'
import friz10 from '../components/Gallery/_images/frizure/10.jpg'
import friz11 from '../components/Gallery/_images/frizure/11.jpg'
import friz12 from '../components/Gallery/_images/frizure/12.jpg'

const GalleryDB = [
    {
      _id: 1,
      img: slide1
    },
    {
      _id: 2,
      img: slide2
    },
    {
      _id: 3,
      img: slide3
    },
    
  ];

  const AlbumDB = [
    {
      _id: 1,
      img: friz1
    },
    {
      _id: 2,
      img: friz2
    },
    {
      _id: 3,
      img: friz3
    },
    {
      _id: 4,
      img: friz4
    },
    {
      _id: 5,
      img: friz5
    },
    {
      _id: 6,
      img: friz6
    },
    {
      _id: 7,
      img: friz7
    },
    {
      _id: 8,
      img: friz8
    },
    {
      _id: 9,
      img: friz9
    },
    {
      _id: 10,
      img: friz10
    },
    {
      _id: 11,
      img: friz11
    },
    {
      _id: 12,
      img: friz12
    },
    {
      _id: 13,
      img: friz9
    },
    
  ];
  
  export function getSlideShow() {
    return GalleryDB;
  }
  
  export function getAlbum() {
    return AlbumDB;
  }
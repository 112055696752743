import React from "react";
import { getSlideShow } from "../../../db/GalleryDB";

const Slideshow = () => {
  //const images = [slide1, slide2];
  const delay = 3000;

  const images = getSlideShow()
  
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, images.length]);

  return (
    <React.Fragment>
      {" "}
      <div id="slider">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {images.map((image, index) => (
            <img
              src={image.img}
              key={index}
              className="d-block w-100 slide "
              alt="slide-1"
            />
          ))}
        </div>
      </div>
      <div className="slideshowDots">
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Slideshow;

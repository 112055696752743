import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {
    offset: "",
  };

  render() {
    const topPage = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    };

    return (
      <div
        className="footer py-4 d-flex flex-lg-column"
        id="kt_footer"
        //style={{ marginTop: `${this.state.offset}px` }}
      >
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1 footerL">
            <span className="footerTextL">©2022 </span>
            <i className="footerTextL">OddGenetics</i>
          </div>
          <ul className="order-1 footerR">
            <li className="liFooter">
              <Link to="/#" className="linkFooter px-2" onClick={topPage}>
                Uslovi korišćenja
              </Link>
            </li>
            <li className="liFooter">
              <Link to="/#" className="linkFooter px-2" onClick={topPage}>
                Politika privatnosti
              </Link>
            </li>
            <li className="liFooter">
              <Link to="/kontakt" className="linkFooter px-2" onClick={topPage}>
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;

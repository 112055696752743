import "../_styles/style.css";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Navbar extends Component {
  state = {
    menuActive: false,
  };
  showMenu() {
    this.state.menuActive === false
      ? this.setState({ menuActive: true })
      : this.setState({ menuActive: false });
  }
  hideMenu() {
    this.setState({ menuActive: false });
  }
  classMenu() {
    let menuClass =
      this.state.menuActive === false ? "collapse navbar-collapse" : "mobMenu";
    return menuClass;
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <p className="navbar-brand">Xquisite</p>

            <button className="phoneMenu" onClick={() => this.showMenu()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path d="M7.5 34q-.65 0-1.075-.425Q6 33.15 6 32.5q0-.65.425-1.075Q6.85 31 7.5 31q.65 0 1.075.425Q9 31.85 9 32.5q0 .65-.425 1.075Q8.15 34 7.5 34Zm0-8.5q-.65 0-1.075-.425Q6 24.65 6 24q0-.65.425-1.075Q6.85 22.5 7.5 22.5q.65 0 1.075.425Q9 23.35 9 24q0 .65-.425 1.075-.425.425-1.075.425Zm0-8.5q-.65 0-1.075-.425Q6 16.15 6 15.5q0-.65.425-1.075Q6.85 14 7.5 14q.65 0 1.075.425Q9 14.85 9 15.5q0 .65-.425 1.075Q8.15 17 7.5 17Zm7 17v-3H42v3Zm0-8.5v-3H42v3Zm0-8.5v-3H42v3Z" />
              </svg>
            </button>
            <div className={this.classMenu()} id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => this.hideMenu()}
                  >
                    Početna
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/galerija"
                    onClick={() => this.hideMenu()}
                  >
                    Galerija
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="kontakt"
                    onClick={() => this.hideMenu()}
                  >
                    Kontakt
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;

const ReservedSVG = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      width='50px'
      height='50px'
      x="0px"
      y="0px"
      viewBox="0 0 471.836 471.836"
     
    >
      <circle fill='#F2BE3E' cx="235.918" cy="235.918" r="235.918" />
      <polygon
        fill='#F2EF8F'
        points="388.047,357.188 127.833,398.741 94.285,193.224 354.498,151.672 "
      />
      <g>
        <rect
          x="128.465"
          y="216.567"
          transform="matrix(-0.9875 0.1578 -0.1578 -0.9875 494.8586 406.8546)"
          fill='#988534'
          width="205.627"
          height="13.01"
        />

        <rect
          x="134.327"
          y="248.674"
          transform="matrix(-0.9875 0.1577 -0.1577 -0.9875 511.55 469.7617)"
          fill='#988534'
          width="205.624"
          height="13.003"
        />

        <rect
          x="139.693"
          y="282.815"
          transform="matrix(-0.9875 0.1577 -0.1577 -0.9875 527.6082 536.7776)"
          fill='#988534'
          width="205.631"
          height="13.01"
        />
        <polygon
          fill='#988534'
          points="259.274,327.116 147.332,345.001 145.266,332.156 257.212,314.27 	"
        />
      </g>
      <g>
        <polygon
          fill='#7D8880'
          points="225.104,201.572 219.069,163.983 231.824,163.983 225.617,201.572 	"
        />
        <polygon
          fill='#F06668'
          points="197.348,72.384 199.514,76.119 213.269,88.672 206.41,138.943 209.045,161.902 
		218.871,167.477 237.523,167.934 251.207,159.975 242.257,145.513 237.171,90.741 237.433,83.254 249.669,75.793 251.162,71.98 	
		"
        />
        <path
          fill='#E84849'
          d="M248.019,164.787c-4.572,3.027-10.174,4.992-16.292,5.658l-2.058,0.176
		c-0.236,0.019-0.464,0.052-0.7,0.052l-1.89,0.056c-3.753-0.019-7.379-0.513-10.72-1.493c-12.524-3.633-21.549-13.351-21.717-24.879
		c-0.086-6.466,3.308-7.809,9.672-11.977l7.431-43.064c-6.028-2.043-10.802-8.947-13.246-14.196
		c8.254,6.881,17.957,8.52,24.89,8.587c0.236,1.74,0.883,6.687,0.909,7.416c4.426,0.015,8.093-0.636,10.642-1.336l0.007-0.026
		l1.216-7.607c5.422-1.392,10.174-3.633,13.497-6.357c-1.998,4.501-5.28,9.946-9.47,11.966c0,0,5.339,30.581,7.465,43.393
		c6.881,3.948,12.584,5.616,12.685,12.295C260.475,152.043,255.63,159.751,248.019,164.787z M235.072,91.138
		c-2.623,0.7-6.309,1.343-10.754,1.325l-0.296,58.993c1.916,0.172,3.847,0.239,5.736,0.086c4.015-0.337,7.633-1.5,10.6-3.3
		L235.072,91.138z M248.681,160.686l-7.903-11.139c-3.102,1.815-6.806,2.986-10.907,3.33c-1.983,0.168-4.007,0.079-6.028-0.101
		l-5.729,12.561l-0.041,0.094c3.094,0.606,6.357,0.913,9.744,0.868c7.936-0.116,15.195-2.14,20.834-5.422L248.681,160.686z
		 M227.346,82.259c-6.941,0.509-20.041-0.116-29.998-9.875c-0.651-1.25-1.055-2.552-1.07-3.914
		c-0.019-1.474,0.378-2.904,1.096-4.269c3.356-6.238,14.002-10.889,26.686-11.076c12.685-0.168,23.453,4.198,26.971,10.331
		c0.778,1.343,1.205,2.765,1.231,4.243c0.019,1.482-0.37,2.919-1.104,4.284c-0.079,0.228-0.187,0.505-0.281,0.745
		C246.709,77.653,237.22,81.54,227.346,82.259z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default ReservedSVG;

import React, { Component } from "react";
import GalleryAlbum from "./sections/Album";


class Gallery extends Component {
  state = {
    slideShow: [],
  };

  render() {
    return (
      <div>
        
        <GalleryAlbum />
      </div>
    );
  }
}

export default Gallery;

import React, { Component } from 'react';
import MidSec from './sections/MidSec';
import Slideshow from './sections/SlideShow';

import salon from './_images/icons/salon.png';
import hairDryer from './_images/icons/hair-dryer.png';
import hairDye from './_images/icons/hair-dye(1).png';
import hairstyle from './_images/icons/hairstyle.png';
import bride from './_images/icons/bride.png';

import comb from './_images/icons/comb.png';
import comfort from './_images/icons/comfort.png';
import cream from './_images/icons/cream.png';
import tehq from './_images/icons/tehq.png';
import pro from './_images/icons/pro.png';
import { Link } from 'react-router-dom';

class Home extends Component {
    render() {
        const topPage = () => {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 10);}
        return (
            <React.Fragment>
                <section className='containter top'>
                    <div className='row slideshow-row'>
                        <div className='col-6 top-1 slideshow-col'>
                            <div className='slideShow'>
                                <Slideshow />
                            </div>
                        </div>
                        <div className='col-5 top-2 header-col'>
                            <h1>Frizerski salon Xquisite</h1>

                            <p>
                                Otkrijte vrhunsku frizersku uslugu u našem
                                salonu gde se ljepota susreće sa stručnošću. Uz
                                najnovije trendove u friziranju i
                                personalizovane pristupe, naš tim iskusnih
                                frizera posvećen je pružanju nezaboravnog
                                iskustva.
                            </p>
                            <p>
                                Posetite nas i prepustite se transformaciji u
                                našem salonu. Vaša ljepota je naša strast!
                            </p>
                        </div>
                    </div>
                </section>
                <section className='mid'>
                    <MidSec />
                </section>
                <section className='bottom '>
                    <div className='container'>
                        <div className='row'>
                            <div className='col bottom-1'>
                                <h1>Naše usluge: </h1>
                                <p>
                                    <ul>
                                        <li>
                                            <img
                                                alt='Makaze i cesalj'
                                                src={salon}
                                                width={40}
                                                height={40}
                                            />
                                            Šišanje i stilizovanje{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Bojenje kose'
                                                src={hairstyle}
                                                width={40}
                                                height={40}
                                            />
                                            Bojenje kose i pramenovi
                                        </li>
                                        <li>
                                            <img
                                                alt='Feniranje'
                                                src={hairDryer}
                                                width={40}
                                                height={40}
                                            />
                                            Feniranje i oblikovanje frizura{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Vjencanica'
                                                src={bride}
                                                width={40}
                                                height={40}
                                            />
                                            Posebne usluge za vjenčanja i
                                            posebne prilike{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Tretman kose'
                                                src={hairDye}
                                                width={40}
                                                height={40}
                                            />
                                            Tretmani za njegu kose
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className='col bottom-2'>
                                <h1>Zašto nas izabrati? </h1>
                                <p>
                                    <ul>
                                        <li>
                                            <img
                                                alt='Profesionalni frizer'
                                                src={pro}
                                                width={40}
                                                height={40}
                                            />
                                            Profesionalni frizeri sa bogatim
                                            iskustvom
                                        </li>
                                        <li>
                                            <img
                                                alt='Cetkica i farba'
                                                src={comb}
                                                width={40}
                                                height={40}
                                            />
                                            Individualni pristup svakom klijentu{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Preparat za kosu'
                                                src={cream}
                                                width={40}
                                                height={40}
                                            />
                                            Korišćenje vrhunskih proizvoda za
                                            njegu kose{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Prijatna atmosfera'
                                                src={comfort}
                                                width={40}
                                                height={40}
                                            />
                                            Prijatna atmosfera i udoban prostor{' '}
                                        </li>
                                        <li>
                                            <img
                                                alt='Inovativni stil'
                                                src={tehq}
                                                width={40}
                                                height={40}
                                            />
                                            Unapređujemo vaš stil inovativnim
                                            tehnikama.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col rezervacija-col'>
                            <Link to='/kontakt' onClick={topPage} className='rezervacijaLink'>
                                <span class='btn dugmence rezervacija shadowing'>
                                    Rezerviši termin
                                </span>
                            </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Home;

import React from "react";

const CardData = ({id, img}) => {
  if ((img)) {
    return (
      <React.Fragment>
        <img
          src={img}
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          alt={`Slika ${id}`}
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        />

       
       
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <svg
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          aria-label="Under construction"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          
          <rect width="100%" height="100%" fill="#55595c"></rect>
          <text x="50%" y="50%" fill="#eceeef" dy=".3em">
            No touchy
          </text>
        </svg>
       
      </React.Fragment>
    );
  }
};

export default CardData;
